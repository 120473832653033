<template>
	<div class="page classdetail_page">
		<div class="block30"></div>
		<div class="w1200 classdetail" v-if="uid">
			<div class="leftbox">
				<div class="titlename fontsize20">{{classObj.title}}</div>
				<div class="progressbox">
					<div class="text fontsize14">已学<span>{{progresstext}}%</span></div>
					<div class="progresscon"><el-progress :percentage="progresstext" :show-text="false" color="#1682FD"></el-progress></div>
				</div>
				<div class="listbox">
					<div class="itemobj" v-for="(item,index) in chapterList" :key="index">
						<div class="titlebox">
							<div class="text1 fontsize14">{{item.title}}</div>
						</div>
						<div class="listbox">
							<div class="itembox" @click="getcourseobjbyindex(index,sonindex)" :class="{'active':chapterindex==index&&courseindex==sonindex}" v-for="(sonitem,sonindex) in item.trainChapterCourseList" :key="sonindex">
								<img class="img" src="../../assets/static/Subtract.png">
								<div class="text1 fontsize14">{{index+1}}-{{sonindex+1}} {{sonitem.courseTitle}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="rightbox">
				<div class="videobox" v-if="courseobj">
					<!-- <div class="playicon"><img class="img100" src="../../assets/static/Group246.png"></div> -->
					<div class="videodiv">
						<video class="video" :src="courseobj.videoCourse" controls controlslist="nodownload"></video>
					</div>
				</div>
				<div class="typebox1 fontsize16" v-if="!courseobj">暂无内容可查看！</div>
				<div class="typebox1" v-if="courseobj">
					<div class="typediv fontsize20">
						<div class="line"></div>
						<div class="text">{{courseobj.courseTitle}}</div>
					</div>
					<div class="desctext fontsize16" v-html="courseobj.introduction"></div>
					<div class="block20"></div>
					<div class="typediv fontsize20">
						<div class="line"></div>
						<div class="text">随堂作业</div>
					</div>
					<div class="zuoyebox">
						<div class="item1 fontsize14">
							<div class="text1">题号</div>
							<div class="text2">标题</div>
							<div class="text3">标签</div>
							<div class="text4">难度</div>
						</div>
						<div class="itemobj" v-for="(item,index) in zyssList" :key="index" @click="toquesdetail(item.id)">
							<div class="text1">{{item.questNumber}}</div>
							<div class="text2">{{item.title}}</div>
							<div class="text3 fontsize14">
								<el-tag :type="tagcolor[Math.floor(Math.random() * 3)]" v-for="(tagitem,tagindex) in item.infoTags" :key="tagindex">{{tagFormat(tagitem)}}</el-tag>
							</div>
							<div class="text4">
								<span class="span1">{{difficultyList[item.difficulty]}}</span>
							</div>
						</div>
						<div class="nolist" v-if="zyssList.length<=0">暂无相关记录！</div>
					</div>
				</div>
			</div>
		</div>
		<div class="block30"></div>
	</div>
</template>

<script>
import {mapState} from 'vuex'
import {
		showLoading,
		hideLoading
	} from '@/common/loading.js';
export default {
	data() {
		return {
			uid:"",//目录课程ID
			chapterId:null,//指定章节
			chapterindex:null,//指定章节
			courseId:null,//指定小结id
			courseindex:null,//观看的课程下标
			courseobj:null,//观看的课程内容
			classObj:{},//课程目录详情
			chapterList:[],
			progresstext:0,//进度
			zyssList:[],//作业赛事
			questionType:[],//题目类型
			tagcolor:["","success","danger",'warning'],//颜色
		};
	},
	mounted: function() {
		if(!this.isLogin){
			this.$message.error("请先返回首页登录！")
			return false;
		}
		if(this.$route.query&&this.$route.query.uid){
			this.uid = this.$route.query.uid
			if(this.$route.query.chapterId){
				this.chapterId = this.$route.query.chapterId
			}
			if(this.$route.query.courseId){
				this.courseId = this.$route.query.courseId
			}
			//获取我目录详情
			this.getScheduleById()
			//获取题目类型
			this.gethlQuestType()
		}else{
			this.$message.error("参数丢失，请返回")
			this.$alert('"参数丢失，请返回！', '提示', {
			    confirmButtonText: '好的',
			    callback: action => {
					this.sysgoback()
			    }
			});
		}
	},
	computed: {
		...mapState(['userInfo', 'isLogin','sysRole','difficultyList']),
	},
	methods: {
		//作业题目详情
		toquesdetail(uid){
			this.$router.push({
				path: "/classQuestionDetail",
				query:{
					uid:uid,
					connectId:this.courseobj.connectId,
					courseId:this.chapterList[this.chapterindex].trainChapterCourseList[this.courseindex].courseId
				}
			});
		},
		//下标获取
		async getcourseobjbyindex(chapterindex,courseindex){
			console.log("get course con")
			var _this = this
			//判断章节是不是免费，不免费就看有没有给钱
			if(this.classObj.trainUserSchedule.payStatus==0&&this.chapterList[chapterindex].allOpen!=0){
				//没给钱，并且不是免费开放的，查免费的下标
				this.$confirm("此章节课程内容需要付费才能观看，请先购买本课程", '提示', {
					confirmButtonText: '去购买',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					_this.$router.push({
						path: "/dopay",
						query:{
							uid:_this.uid,
							chapterId:_this.chapterId
						}
					});
				}).catch(() => {});
			}else{
				//查询内容
				var params = {}
				params["courseId"] = this.chapterList[chapterindex].trainChapterCourseList[courseindex].courseId
				params["trainUserScheduleCourseId"] = this.chapterList[chapterindex].trainChapterCourseList[courseindex].trainUserScheduleCourse.id
				showLoading()
				this.$http.post('TrainModule/train-user-schedule2/userGetCourse', params).then(function(res) {
					hideLoading()
					//数据处理
					if (res.code == 200) {
						_this.chapterindex = chapterindex
						_this.courseindex = courseindex
						_this.courseobj = res.data
						//获取类型json
						res.data.questInfoList.forEach((item, index) => {
							try{
								item["infoTags"] = JSON.parse(item.infoTags)
							}catch(e){
								item["infoTags"] = []
							}
						});
						_this.zyssList = res.data.questInfoList
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			}
		},
		// //获取题目
		// userGetCourseQuestionBank(params){
		// 	var _this = this 
		// 	//查询内容
		// 	showLoading()
		// 	this.$http.post('TrainModule/train-user-schedule2/userGetCourseQuestionBank', params).then(function(res) {
		// 		hideLoading()
		// 		//数据处理
		// 		if (res.code == 200) {
		// 			_this.zyssList = res.data
		// 		} else {
		// 			_this.$confirm(res.message, '提示', {
		// 				confirmButtonText: '确定',
		// 				cancelButtonText: '取消',
		// 				type: 'warning'
		// 			}).then(() => {}).catch(() => {});
		// 		}
		// 	})
		// },
		//获取题目类型
		gethlQuestType(){
			var _this = this 
			var params = {
				currentPage: 1,
				pageSize: 888
			}
			this.$http.post('app/hlexam/hlQuestType/index', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					_this.questionType = res.data.records
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//标签
		tagFormat(tag){
			const obj = this.questionType.find((ifitem) => { //这里的options就是上面遍历的数据源
				return ifitem.typeCode == tag; //筛选出匹配数据
			});
			if(obj){
				return obj.title
			}else{
				return tag
			}
		},
		//获取我目录详情
		getScheduleById(){
			var _this = this
			//需要登录
			if(this.isLogin){
				var params = {}
				params["scheduleId"] = this.uid
				params["userUuid"] = this.userInfo.uuid
				this.$http.post('TrainModule/train-user-schedule2/userGetScheduleProgress', params).then(function(res) {
					//数据处理
					if (res.code == 200) {
						if(res.data.schedule){
							_this.classObj = res.data.schedule
							if(!_this.classObj.trainUserSchedule){
								_this.$message.error("请先加入课程学习")
								_this.$router.push({
									path: '/class',
									query: {
										uid: _this.uid
									}
								})
							}
						}
						if(res.data.total&&res.data.total!=0){
							//进度
							if(res.data.success!=0){
								_this.progresstext = Number((res.data.success / res.data.total)  * 100);
							}
						}
						//获取我目录章节
						_this.userGetScheduleChapter()
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			}
		},
		//获取我目录章节
		userGetScheduleChapter(){
			var _this = this
			//需要登录
			if(this.isLogin){
				var params = {
					currentPage:1,
					pageSize:888,
				}
				params["scheduleId"] = this.uid
				params["userUuid"] = this.userInfo.uuid
				this.$http.post('TrainModule/train-user-schedule2/userGetScheduleChapter', params).then(function(res) {
					//数据处理
					if (res.code == 200) {
						var chapterList = res.data
						//根据id获取下标跳转过去
						var openindex = 0
						var courseindex = 0
						if(_this.chapterId){
							openindex = chapterList.findIndex((item) => { // 数据源
								return item.chapterId == _this.chapterId // 筛选出匹配数据
							})
							//判断有没有给钱0未支付 1已支付，或者免费 
							if(_this.classObj.trainUserSchedule.payStatus==0&&chapterList[openindex].allOpen!=0){
								//没给钱，并且不是免费开放的，查免费的下标
								openindex = chapterList.findIndex((item) => { // 数据源
									return item.allOpen === 0 // 筛选出匹配数据
								})
							}
						}else{
							//没有查免费的下标
							openindex = chapterList.findIndex((item) => { // 数据源
								return item.allOpen === 0 // 筛选出匹配数据
							})
						}
						//获取小结下标
						if(_this.courseId){
							courseindex = chapterList[openindex].trainChapterCourseList.findIndex((item) => { // 数据源
								return item.courseId == _this.courseId // 筛选出匹配数据
							})
							if(courseindex<0){
								courseindex = 0
							}
						}
						_this.chapterindex = openindex
						_this.courseindex = courseindex
						_this.chapterList = chapterList
						_this.getcourseobjbyindex(_this.chapterindex,_this.courseindex)
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			}
		},
		
		
	}
};
</script>
<style lang="scss" scoped></style>
